.portfolioHeader {
  background-size: 0;
  border-width: 0;
  border-color: var(--background-color) !important;
  background-color: var(--background-color) !important;
  margin-top: .5rem;
}

.menuBody {
  font-size: 18pt;
}

.menuHeader {
  background-color: var(--primary-color) !important; 
  border-bottom: var(--border-color);
}

.menuTitle {
  font-size: 22pt;
}

.subMenu {
  text-align: center;
  font-size: 14pt;
}
