
:root {
  --primary-color: #f1eaea;
  --secondary-color: blue;
  --border-color: 1px solid #b8aaaa;
  --link-active-color: #b56666 !important;
  --link-color: black !important;
  --background-color: white;
}
body {
  margin: 0;
  font-family: Cambria, ‘Hoefler Text’, Utopia, ‘Liberation Serif’,
    ‘Nimbus Roman No9 L Regular’, Times, ‘Times New Roman’, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:visited {
  color: var(--link-color);
  text-decoration: 0;
}

a:hover, a:active {
  color: var(--link-active-color);
} 

a.dropdown-item:hover {
  background-color: var(--primary-color);
}
