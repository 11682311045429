.portfolioFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
  border-top: var(--border-color);
  background-color: var(--primary-color); 
}

.footerImage {
  width: 5rem;
}
