
.centerOnPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}  

img.me {
  width: 15rem;
  height: 15rem;
  background: rgb(221 225 233);
  border-radius: 1.5rem;
  object-fit: cover;
  padding: 0.05rem;
}

h6 {
  text-align: center;
}