
.projectPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}  

img.recipe {
  width: 50vmin;
  background: white;
  border-radius: .2rem;
  object-fit: contain;
  padding: 0rem;
  border: 1px rgb(216, 214, 214) solid;
}


